global.$ = global.jQuery = require('jquery');

require('bootstrap');

require('jquery-lazy');

require('jquery.easing');

window.Swiper = global.Swiper = require('swiper').default;

require('magnific-popup');


